<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="searchData" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择影院组织" v-model:value="searchData.organizationId" style="width: 190px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="放映时间" name="cinemaId">
						<a-date-picker v-model:value="time" :disabled-date="disabledDate"/>
					</a-form-item>

					<a-form-item class="ui-form__item" label="" >
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-form-item>
				</a-row>
			
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="false" :columns="columns" :dataSource="list" :scroll="{ x: 1200 }" tableLayout="auto">
				</a-table>
			</div>
			
		</a-spin>
	</div>
</template>

<script>
	import { ticketIncomeByCinema} from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import moment from 'moment';

	export default {
		data() {
			return {
				loading: false,
				searchData: {
					organizationId: 0,
					cinemaId: 0,
					startTime: 0,
					endTime: 0,
				},
				time: moment(new Date()),
				organizationList: [],
				cinemaAllList: [],
                columns: [
                	{title: '影院',key: 'subjectName',dataIndex: 'subjectName',fixed:true,align:'left', width: 220}, 
                	{title: '微信人数',key: 'weixinCount',dataIndex: 'weixinCount',align:'center', width:100}, 
                	{title: '微信收入',key: 'weixinIncome',dataIndex: 'weixinIncome',align:'center', width:100}, 
                	{title: '券补差人数',key: 'couponSubsidyCount',dataIndex: 'couponSubsidyCount',align:'center', width:100}, 
                	{title: '券补差收入',key: 'couponSubsidyIncome',dataIndex: 'couponSubsidyIncome',align:'center', width:100}, 
                	{title: '券不补差人数',key: 'couponNoSubsidyCount',dataIndex: 'couponNoSubsidyCount',align:'center', width:100}, 
					{title: '次卡补差人数',key: 'timesCardSubsidyCount',dataIndex: 'timesCardSubsidyCount',align:'center', width:100}, 
                	{title: '次卡补差收入',key: 'timesCardSubsidyIncome',dataIndex: 'timesCardSubsidyIncome',align:'center', width:100}, 
					{title: '次卡不补人数',key: 'timesCardNoSubsidyCount',dataIndex: 'timesCardNoSubsidyCount',align:'center', width:100}, 
					{title: '年卡人数',key: 'yearCardCount',dataIndex: 'yearCardCount',align:'center', width:100}, 
                	{title: '影城会员卡人数',key: 'storedCardCount',dataIndex: 'storedCardCount',align:'center', width:100}, 
                	{title: '合计人数',key: 'totalCount',dataIndex: 'totalCount',align:'center', width:100}, 
					{title: '合计收入',key: 'totalIncome',dataIndex: 'totalIncome',align:'center', width:100}
                ],
				list: [],
				
			}
		},
		created(){
			this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {

				const searchData = {
					organizationId: this.searchData.organizationId ? this.searchData.organizationId : undefined,
				}

				if (this.time) {

					const nowTime = this.time.toDate()
					const startTime = new Date(nowTime.setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 
					
					searchData.showStartTime = this.moment(startTime).unix()
					searchData.showEndTime = this.moment(endTime).unix()

				}else{
					// 取当天时间
					const startTime = new Date(new Date().setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 

					searchData.showStartTime = this.moment(startTime).unix()
					searchData.showEndTime = this.moment(endTime).unix()
				}

				
				this.getData(searchData);
			},
			reset() {
				this.$refs.form.resetFields();
				this.cinemaAllList = [];
				this.time = [];
				this.payTime = [];
				this.onSearch();
			},
			mathRound(value, n) {
			    n = n === undefined ? 1 : n;
			    let bei = Math.pow(10, n);
			    let res = Math.round(value * bei) / bei;
			    return +parseFloat(res.toPrecision(12));
			},
			getForbiden(time){

				if(location.href.indexOf('admin.fenghe.gzlingren.com') < 0){
					return false;
				}else if(location.href.indexOf('debug') > 0){
					return false
				}else{
					return time < this.moment('2023-01-07').endOf('day').unix();
				}

			},
			async getData(searchData) {
				const postData = {
					...searchData,
					page: 1,
					pageSize: 10,
				}
				

				this.loading = true;
				try {
					const {data} = await ticketIncomeByCinema(postData);
					const result = [];
					const total = {}

					if(this.getForbiden(postData.showEndTime)){
						// 不给看
					}else{

						// 重置
						this.columns.map(item=>item.key != 'subjectName' && (total[item.key] = 0))
						if(data && data.length > 0){
							data.map(item=>Object.keys(item).map(key=>key != 'subjectName' && (total[key] += item[key])))

							// 浮点小数处理
							Object.keys(total).map(key=>total[key] = this.mathRound(total[key],2))
							total.subjectName = '合计'

							result.push(...data);
							result.push(total)
						}

					}

					this.loading = false;
					this.list = result


				} catch (error) {
					this.loading = false;
					console.log(error);
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.searchData.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			
			getCinemaId(value){
				this.cinemaIds = value;
			},
			disabledDate(current) {
                return current && current >= this.moment().endOf('day');
            }
           
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
